import { updateEditorStateAsync } from '@/editor/actions/updateEditorState';
import { updateTitleEditorStateAsync } from '@/editor/actions/updateTitleEditorState';
import { getTitleEditorComments, getEditorComments } from '@/editor/selectors';
import getEditorStateWithoutTempComment from '@/editor/utils/getEditorStateWithoutTempComment';

function tempTitleEditorCommentClean({ state, comments, dispatch }) {
  const [cleanEditorState, hasChanged] = getEditorStateWithoutTempComment(
    state.editor.titleEditorState,
    {
      editorComments: getTitleEditorComments(state),
      comments,
    },
  );

  if (hasChanged) {
    dispatch(updateTitleEditorStateAsync(cleanEditorState));
  }
}

function tempContentEditorCommentClean({ state, comments, dispatch }) {
  const [cleanEditorState, hasChanged] = getEditorStateWithoutTempComment(
    state.editor.editorState,
    {
      editorComments: getEditorComments(state),
      comments,
    },
  );

  if (hasChanged) {
    dispatch(updateEditorStateAsync(cleanEditorState));
  }
}

/**
 * This function removes all the temporary comments on all the editors. Temporary comments are new comments that have not yet been saved.
 * We allow only one temporary comment to exist at a time.
 * @param {Array} comments
 * @returns {Function}
 */
export default function cleanAllTempEditorComments(comments) {
  return (dispatch, getState) => {
    const state = getState();

    tempTitleEditorCommentClean({ state, comments, dispatch });
    tempContentEditorCommentClean({ state, comments, dispatch });
  };
}
