import { cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CommentsProvider from '@/comments/components/CommentsProvider';
import { getTitleEditorComments, getEditorComments } from '@/editor/selectors';
import useComments from '@/comments/hooks/useComments';
import cleanAllTempEditorComments from '@/editor/actions/cleanAllTempEditorComments';
import ModelProvider from '@/storychief/components/ModelProvider';
import { useDidUpdateEffect } from '@/storychief/hooks';

function StoryCommentsSidebar({ children }) {
  const { comments, isNewGeneralCommentActive, setIsNewGeneralCommentActive } = useComments();

  const titleEditorComments = useSelector(getTitleEditorComments);
  const contentEditorComments = useSelector(getEditorComments);
  const story = useSelector((state) => state?.story || null);
  const storyStatus = useSelector((state) => state?.story?.status || null);
  const tempTitleEditorComment = titleEditorComments.find((comment) =>
    comments.every((c) => c.editor_key !== comment.key),
  );
  const tempContentEditorComment = contentEditorComments.find((comment) =>
    comments.every((c) => c.editor_key !== comment.key),
  );

  const tempEditorComment = tempTitleEditorComment || tempContentEditorComment || null;
  const [isInitialized, setIsInitialized] = useState(false);
  const dispatch = useDispatch();

  function handleOnTempEditorCommentClean() {
    if (!story) return;

    dispatch(cleanAllTempEditorComments(comments));
  }

  // Effects
  useDidUpdateEffect(() => {
    if (story && isNewGeneralCommentActive) {
      dispatch(cleanAllTempEditorComments(comments));
    }
  }, [isNewGeneralCommentActive]);

  useDidUpdateEffect(() => {
    if (story && tempEditorComment && isNewGeneralCommentActive) {
      setIsNewGeneralCommentActive(false);
    }
  }, [tempEditorComment]);

  useEffect(() => {
    if (!storyStatus && !isInitialized) {
      setIsInitialized(true);
    }
  }, [storyStatus]);

  return cloneElement(children, {
    isLoading: !isInitialized,
    tempEditorComment,
    onTempEditorCommentClean: handleOnTempEditorCommentClean,
  });
}

function PostsetCommentsSidebar({ children }) {
  return children;
}

function getModelComponent(modelType) {
  if (modelType === 'Story') {
    return StoryCommentsSidebar;
  }

  return PostsetCommentsSidebar;
}

export default function withComments(WrappedComponent) {
  const propTypes = {
    modelType: PropTypes.string.isRequired,
  };

  function CommentsWrapper(props) {
    const ModelComponent = getModelComponent(props.modelType);

    return (
      <ModelProvider {...props}>
        <CommentsProvider {...props}>
          <ModelComponent>
            <WrappedComponent {...props} />
          </ModelComponent>
        </CommentsProvider>
      </ModelProvider>
    );
  }

  CommentsWrapper.propTypes = propTypes;

  return CommentsWrapper;
}
