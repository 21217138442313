import { useReactiveVar } from '@apollo/client';
import { selectedCommentEditorKeyVar } from '@/graphql/cache';
import { usePrevious } from '@/storychief/hooks';

/**
 * @param {{ editor_key: string }[]}comments
 * @returns {{selectedComment: string, selectedCommentEditorKey: string, prevSelectedCommentEditorKey: string, setSelectedCommentEditorKey: Function}}
 */
export default function useSelectedComment(comments = []) {
  const selectedCommentEditorKey = useReactiveVar(selectedCommentEditorKeyVar);
  const prevSelectedCommentEditorKey = usePrevious(selectedCommentEditorKey);

  function setSelectedCommentEditorKey(key) {
    selectedCommentEditorKeyVar(key);
  }

  function getSelectedComment() {
    return comments.find((comment) => comment.editor_key === selectedCommentEditorKey);
  }

  return {
    selectedComment: getSelectedComment(),
    selectedCommentEditorKey,
    prevSelectedCommentEditorKey,
    setSelectedCommentEditorKey,
  };
}
