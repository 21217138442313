import deleteEditorComment from '@/editor/utils/deleteEditorComment';

function getTempEditorComment(editorComments, comments) {
  return editorComments.find((editorComment) =>
    comments.every((c) => c.editor_key !== editorComment.key),
  );
}

/**
 * @param {object} editorState
 * @param {{editorComments: Array, comments: Array}} commentsData
 * @returns {[editorState: object, hasTempCommentBeenRemoved: boolean]}
 */
export default function getEditorStateWithoutTempComment(editorState, commentsData) {
  const { editorComments, comments } = commentsData;
  const tempComment = getTempEditorComment(editorComments, comments);
  const cleanEditorState = tempComment
    ? deleteEditorComment(tempComment, editorState)
    : editorState;

  return [cleanEditorState, !!tempComment];
}
